import { useRef, useState } from "react";

const OneMonthWithKhem = ({ onPlay }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const playWithSound = () => {
        const video = videoRef.current;
        if (video) {
            video.muted = false; // Unmute the video
            video.volume = 1.0;  // Set volume
            video.play();        // Play the video
            setIsPlaying(true);  // Update state to indicate the video is playing
            onPlay();            // Trigger the countdown in the parent component
        }
    };

    return (
        <main className="border w-full h-full">
            {!isPlaying && (
                <button
                    onClick={playWithSound}
                    className="flex flex-col absolute z-10 w-full h-full  items-center justify-center bg-black bg-opacity-50 text-white text-xl"
                >
                    <h1 className="font-bold text-5xl">Happy 1-Month Anniversary</h1>
                    <h1 className="font-bold text-sm mt-2.5">"I hope we can live together for many years."</h1>
                </button>
            )}
            <video
                ref={videoRef}
                src="1month.mp4"
                className="w-full h-full aspect-video absolute left-0"
                type="video/mp4"
                loop
            />
        </main>
    );
};

export default OneMonthWithKhem;
