import React, { useState, useEffect } from 'react';
import './App.css';  // You can keep your custom styles in this file
import CountdownTimer from './src/CountdownTimer';
import OneMonthWithKhem from './src/1Month';
// import DrawingBoard from './src/DrawingBoard';  // The drawing board component

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);  // Store the drawn image URL
  const [isButtonVisible, setIsButtonVisible] = useState(false);  // State to control button visibility
  const [isHovered, setIsHovered] = useState(false);  // State to track hover
  const [isMobile, setIsMobile] = useState(false);  // State to track if the user is on mobile/iPad
  const [message, setMessage] = useState('มีไรจะบอก');  // Default message

  useEffect(() => {
    // Detect mobile or tablet device using window.navigator
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /android/i.test(userAgent) ||
      /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.maxTouchPoints && navigator.maxTouchPoints > 1)
    ) {
      setIsMobile(true);

      // For mobile/iPad, automatically change the message after 3 seconds
      setTimeout(() => {
        setMessage('อาร์มจะตั้งใจรักหนูอย่างดีเลย');
      }, 3000);  // 3 seconds delay
    }
  }, []);

  // Handle the modal toggle
  const handleTap = () => {
    setIsModalOpen(true);  // Open the modal when user taps the button
  };

  const closeModal = () => {
    setIsModalOpen(false);  // Close the modal
  };

  // Function to handle saving the drawing
  const handleSaveImage = (dataUrl) => {
    setImageUrl(dataUrl);  // Set the image URL in the state
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'drawing.png';  // Trigger the download of the image
    link.click();
  };

  // Show the button after 12.8 seconds of the video playing
  const startButtonCountdown = () => {
    const timer = setTimeout(() => {
      setIsButtonVisible(true);  // Show the button after 12.8 seconds
    }, 12800);  // 12.8 seconds delay

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  };

  return (
    <div className="relative bg-white w-full h-screen overflow-hidden">

      {/* Video and Countdown */}
      <OneMonthWithKhem onPlay={startButtonCountdown} />
      <CountdownTimer />

      {/* Tap Area Button - Only visible after 12 seconds */}
      {isButtonVisible && (
        <div
          className="text-white absolute h-8 w-max flex justify-center items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2.5 rounded-full text-xl font-semibold cursor-pointer shadow-lg transition"
          onClick={handleTap}
          onMouseEnter={() => !isMobile && setIsHovered(true)}  // Hover effect only for non-mobile devices
          onMouseLeave={() => !isMobile && setIsHovered(false)}  // Remove hover effect on mouse leave
        >
          {isMobile
            ? message  // Automatically changing message for mobile/iPad
            : isHovered
            ? 'อาร์มจะตั้งใจรักหนูอย่างดีเลย'  // Hover message for desktop
            : 'มีไรจะบอก'}  {/* Default message for desktop */}
        </div>
      )}

      {/* Modal with Drawing Board */}
      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <DrawingBoard onSave={handleSaveImage} />
      </Modal> */}

      {/* Display saved image */}
      {imageUrl && (
        <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 p-4 bg-white shadow-lg rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Your Drawing:</h3>
          <img
            src={imageUrl}
            alt="Drawing"
            className="max-w-full h-auto rounded-lg shadow-md"
          />
        </div>
      )}

    </div>
  );
}

export default App;
