import React, { useState, useEffect } from "react";

const CountdownTimer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    // Set the start date (October 31, 2024)
    const startDate = new Date("Oct 31, 2024").getTime();

    // Update the count every 1 second
    const interval = setInterval(() => {
      // Get the current date and time
      const now = new Date().getTime();

      // Find the distance between now and the start date
      let distance = now - startDate; // Reverse the calculation for counting up

      // Time calculations for days, hours, minutes, and seconds
      const calculatedDays = Math.floor(distance / (1000 * 60 * 60 * 24));
      const calculatedHours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const calculatedMinutes = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      const calculatedSeconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update state variables
      setDays(calculatedDays);
      setHours(calculatedHours);
      setMinutes(calculatedMinutes);
      setSeconds(calculatedSeconds);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="absolute left-1/2 transform -translate-x-1/2 bottom-3 flex justify-center items-center h-auto">
      <div className="flex justify-center space-x-4">
        <div className=" w-24 h-24 flex flex-col justify-center items-center">
          <p className="text-5xl font-bold">{days}</p>
          <p className="text-sm">days</p>
        </div>
        <div className=" w-24 h-24 flex flex-col justify-center items-center">
          <p className="text-5xl font-bold">{hours}</p>
          <p className="text-sm">hours</p>
        </div>
        <div className=" w-24 h-24 flex flex-col justify-center items-center">
          <p className="text-5xl font-bold">{minutes}</p>
          <p className="text-sm">minutes</p>
        </div>
        <div className=" w-24 h-24 flex flex-col justify-center items-center">
          <p className="text-5xl font-bold">{seconds}</p>
          <p className="text-sm">seconds</p>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
